<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        Paramètres > Utilisateurs >
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des utilisateurs
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="row">
        <div class="div_left">
          <router-link :to="{ path: '/utilisateurs/ajouter' }"
                       class="btn btn-blue">
            Ajouter un utilisateur
          </router-link>
        </div>
        <div class="div_left mx-auto">
          <button class="btn btn-blue pl-5 pr-5 op-btn"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#filtreModal">Filtrer</button>
        </div>
      </div>
      
      <div class="tab_holder mt-4">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th scope="col firstth">Nom &amp; prénoms</th>
              <th scope="col"
                  class="text-left">Email</th>
              <th scope="col"
                  class="text-left">Fonction</th>
              <th scope="col"
                  class="text-left">Entreprise</th>
              <th scope="col"
                  class="text-left">Créé le</th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td colspan="5"
                  class="text-center">Chargement...</td>
            </tr>
          </tbody>
          <tbody v-else-if="long != 0">
            <tr v-for="(user, key) in listUsers.donnees"
                :key="key"
                @click="details(user.id)">
              <td class="col-md-3">{{user.nom}}</td>
              <td class="col-md-3 ">{{user.email}}</td>
              <td class="col-md-2 ">{{user.type}}</td>
              <td class="col-md-2 ">{{user.groupe}}</td>
              <td class="col-md-2 ">{{user.date}}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5"
                  class="text-center">Aucun utilisateur enregistré!</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row pagination">
        <div v-if="listUsers.pagination"
             class="col-md-2 mx-auto text-center">
          <span v-if="listUsers.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(listUsers.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="listUsers.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(listUsers.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.48em;
} */
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import ListFilter from "@/components/shared/Filter"

export default {
  name: "Utilisateur",
  components:{
    ListFilter,
  },
  data: () => ({
    userData: {
      limite: 10,
      avant: "MQ=="
    },
    filtre:[],
    chmp:[
      {
        valeur:"nom",
        type:"caractere",
        table:"utiliateurs",
        libelle:"Nom",
      },
      {
        valeur:"email",
        type:"caractere",
        table:"utilisateurs",
        libelle:"Email",
      },
      {
        valeur:"type_utilisateur",
        type:"caractere",
        table:"utilisateurs",
        libelle:"Fonction",
      },
      {
        valeur:"groupe_utilisateur",
        type:"caractere",
        table:"utilisateurs",
        libelle:"Entreprise",
      },
      {
        valeur:"created_at",
        type:"date",
        table:"utilisateurs",
        libelle:"Créé le",
      },
    ],
    long: "",
    pIndex: 1,
    loaded: false,
  }),
  watch: {
    listUsers() {
      console.log(this.listUsers)
      if (this.listUsers.donnees) {
        this.loaded = true
        this.long = this.listUsers.donnees.length
        this.$refs["btn-close"].click()
      }
    }
  },
  created() {
    this.getListUsers()
  },
  computed: {
    ...mapGetters(["listUsers", "errors"])
  },
  methods: {
    ...mapActions(["getListUsers","pageUsers", "setErors"]),
    ...mapMutations(["setListUsers", "setErors"]),
    filtrer() {
      this.loaded = false
      this.long = null
      this.setListUsers("")
      this.getListUsers(this.filtre)
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.loaded = false
      this.long = null
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageUsers(url)
      
      // this.pageProgrammes(url)
    },
    details(id){
      this.$router.push({ name: "UpdateUser", params: { id: id } })
    }
  }
}
</script>
